import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, map } from 'rxjs';
import { GoogleAuthProvider } from 'firebase/auth';
import { User } from '../models/user.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<User | null>;

  constructor(private afAuth: AngularFireAuth) {
    this.user$ = afAuth.user.pipe(
      map(user => {
        if (!user) {
          return null;
        }

        // Ensure user object has the required properties and handle null values
        const { uid, email, displayName, photoURL } = user;
        return { uid, email: email ?? '', displayName: displayName ?? '', photoURL: photoURL ?? '' };
      })
    );
  }

  public signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    return this.afAuth.signInWithPopup(provider);
  }
}
