import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  constructor(private authService: AuthService) {}
  signInWithGoogle() {
    this.authService
      .signInWithGoogle()
      .then(() => {
        // Handle successful Google sign-in
        alert('Login successuful');
      })
      .catch(error => {
        // Handle Google sign-in error
        console.error(error);
      });
  }
}
