export const environment = {
  production: false,
  local: false,
  baseUrl: 'https://inside.dev.nenasal.com/',
  firebaseConfig: {
    apiKey: 'AIzaSyAEqoctcrm3BW5dstZMBL-VY2ZTa2hbSbc',
    authDomain: 'inside-nenasal.firebaseapp.com',
    projectId: 'inside-nenasal',
    storageBucket: 'inside-nenasal.appspot.com',
    messagingSenderId: '232956615747',
    appId: '1:232956615747:web:c11e31c8311e04b16748f6',
    measurementId: 'G-58PY9V65G3',
  },
  company: {
    name: 'NENASAL LLC',
    address: {
      street: '1007 N Orange',
      suite: '1268',
      city: 'Wilmington',
      state: 'Delaware',
      zip: '19801',
      country: 'United States',
    },
    email: 'office@nenasal.com',
  },
  recaptcha: {
    siteKey: '6LdYqEYqAAAAAEtWl5L6V0FxaCL7389ifhs3kSID',
  },
};
